@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  transition: all 0.5s;
}

header {
  background-color: black;
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.row {
  display: flex;
  align-items: center;
  gap: 50px;

}

.link {
  color: white;
  text-decoration: none;
}

.link:hover {
  color: cyan;
}

nav {
  width: 1320px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
}

section {
  width: 100%;
  height: 100vh;
  background-color: yellow;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px; 
}